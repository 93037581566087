const code = [
  { name: "Aruba", code: "AW", callingCode: "297" },
  { name: "Afghanistan", code: "AF", callingCode: "93" },
  { name: "Angola", code: "AO", callingCode: "244" },
  { name: "Anguilla", code: "AI", callingCode: "1264" },
  { name: "Ã…land Islands", code: "AX", callingCode: "358" },
  { name: "Albania", code: "AL", callingCode: "355" },
  { name: "Andorra", code: "AD", callingCode: "376" },
  { name: "United Arab Emirates", code: "AE", callingCode: "971" },
  { name: "Argentina", code: "AR", callingCode: "54" },
  { name: "Armenia", code: "AM", callingCode: "374" },
  { name: "American Samoa", code: "AS", callingCode: "1684" },
  { name: "Antigua and Barbuda", code: "AG", callingCode: "1268" },
  { name: "Australia", code: "AU", callingCode: "61" },
  { name: "Austria", code: "AT", callingCode: "43" },
  { name: "Azerbaijan", code: "AZ", callingCode: "994" },
  { name: "Burundi", code: "BI", callingCode: "257" },
  { name: "Belgium", code: "BE", callingCode: "32" },
  { name: "Benin", code: "BJ", callingCode: "229" },
  { name: "Burkina Faso", code: "BF", callingCode: "226" },
  { name: "Bangladesh", code: "BD", callingCode: "880" },
  { name: "Bulgaria", code: "BG", callingCode: "359" },
  { name: "Bahrain", code: "BH", callingCode: "973" },
  { name: "Bahamas", code: "BS", callingCode: "1242" },
  { name: "Bosnia and Herzegovina", code: "BA", callingCode: "387" },
  { name: "Saint BarthÃ©lemy", code: "BL", callingCode: "590" },
  { name: "Belarus", code: "BY", callingCode: "375" },
  { name: "Belize", code: "BZ", callingCode: "501" },
  { name: "Bermuda", code: "BM", callingCode: "1441" },
  { name: "Bolivia", code: "BO", callingCode: "591" },
  { name: "Brazil", code: "BR", callingCode: "55" },
  { name: "Barbados", code: "BB", callingCode: "1246" },
  { name: "Brunei", code: "BN", callingCode: "673" },
  { name: "Bhutan", code: "BT", callingCode: "975" },
  { name: "Botswana", code: "BW", callingCode: "267" },
  { name: "Central African Republic", code: "CF", callingCode: "236" },
  { name: "Canada", code: "CA", callingCode: "1" },
  { name: "Cocos (Keeling) Islands", code: "CC", callingCode: "61" },
  { name: "Switzerland", code: "CH", callingCode: "41" },
  { name: "Chile", code: "CL", callingCode: "56" },
  { name: "China", code: "CN", callingCode: "86" },
  { name: "Ivory Coast", code: "CI", callingCode: "225" },
  { name: "Cameroon", code: "CM", callingCode: "237" },
  { name: "DR Congo", code: "CD", callingCode: "243" },
  { name: "Republic of the Congo", code: "CG", callingCode: "242" },
  { name: "Cook Islands", code: "CK", callingCode: "682" },
  { name: "Colombia", code: "CO", callingCode: "57" },
  { name: "Comoros", code: "KM", callingCode: "269" },
  { name: "Cape Verde", code: "CV", callingCode: "238" },
  { name: "Costa Rica", code: "CR", callingCode: "506" },
  { name: "Cuba", code: "CU", callingCode: "53" },
  { name: "CuraÃ§ao", code: "CW", callingCode: "5999" },
  { name: "Christmas Island", code: "CX", callingCode: "61" },
  { name: "Cayman Islands", code: "KY", callingCode: "1345" },
  { name: "Cyprus", code: "CY", callingCode: "357" },
  { name: "Czechia", code: "CZ", callingCode: "420" },
  { name: "Germany", code: "DE", callingCode: "49" },
  { name: "Djibouti", code: "DJ", callingCode: "253" },
  { name: "Dominica", code: "DM", callingCode: "1767" },
  { name: "Denmark", code: "DK", callingCode: "45" },
  { name: "Dominican Republic", code: "DO", callingCode: "1809" },
  { name: "Dominican Republic", code: "DO", callingCode: "1829" },
  { name: "Dominican Republic", code: "DO", callingCode: "1849" },
  { name: "Algeria", code: "DZ", callingCode: "213" },
  { name: "Ecuador", code: "EC", callingCode: "593" },
  { name: "Egypt", code: "EG", callingCode: "20" },
  { name: "Eritrea", code: "ER", callingCode: "291" },
  { name: "Western Sahara", code: "EH", callingCode: "212" },
  { name: "Spain", code: "ES", callingCode: "34" },
  { name: "Estonia", code: "EE", callingCode: "372" },
  { name: "Ethiopia", code: "ET", callingCode: "251" },
  { name: "Finland", code: "FI", callingCode: "358" },
  { name: "Fiji", code: "FJ", callingCode: "679" },
  { name: "Falkland Islands", code: "FK", callingCode: "500" },
  { name: "France", code: "FR", callingCode: "33" },
  { name: "Faroe Islands", code: "FO", callingCode: "298" },
  { name: "Micronesia", code: "FM", callingCode: "691" },
  { name: "Gabon", code: "GA", callingCode: "241" },
  { name: "United Kingdom", code: "GB", callingCode: "44" },
  { name: "Georgia", code: "GE", callingCode: "995" },
  { name: "Guernsey", code: "GG", callingCode: "44" },
  { name: "Ghana", code: "GH", callingCode: "233" },
  { name: "Gibraltar", code: "GI", callingCode: "350" },
  { name: "Guinea", code: "GN", callingCode: "224" },
  { name: "Guadeloupe", code: "GP", callingCode: "590" },
  { name: "Gambia", code: "GM", callingCode: "220" },
  { name: "Guinea-Bissau", code: "GW", callingCode: "245" },
  { name: "Equatorial Guinea", code: "GQ", callingCode: "240" },
  { name: "Greece", code: "GR", callingCode: "30" },
  { name: "Grenada", code: "GD", callingCode: "1473" },
  { name: "Greenland", code: "GL", callingCode: "299" },
  { name: "Guatemala", code: "GT", callingCode: "502" },
  { name: "French Guiana", code: "GF", callingCode: "594" },
  { name: "Guam", code: "GU", callingCode: "1671" },
  { name: "Guyana", code: "GY", callingCode: "592" },
  { name: "Hong Kong", code: "HK", callingCode: "852" },
  { name: "Honduras", code: "HN", callingCode: "504" },
  { name: "Croatia", code: "HR", callingCode: "385" },
  { name: "Haiti", code: "HT", callingCode: "509" },
  { name: "Hungary", code: "HU", callingCode: "36" },
  { name: "Indonesia", code: "ID", callingCode: "62" },
  { name: "Isle of Man", code: "IM", callingCode: "44" },
  { name: "India", code: "IN", callingCode: "91" },
  { name: "British Indian Ocean Territory", code: "IO", callingCode: "246" },
  { name: "Ireland", code: "IE", callingCode: "353" },
  { name: "Iran", code: "IR", callingCode: "98" },
  { name: "Iraq", code: "IQ", callingCode: "964" },
  { name: "Iceland", code: "IS", callingCode: "354" },
  { name: "Israel", code: "IL", callingCode: "972" },
  { name: "Italy", code: "IT", callingCode: "39" },
  { name: "Jamaica", code: "JM", callingCode: "1876" },
  { name: "Jersey", code: "JE", callingCode: "44" },
  { name: "Jordan", code: "JO", callingCode: "962" },
  { name: "Japan", code: "JP", callingCode: "81" },
  { name: "Kazakhstan", code: "KZ", callingCode: "76" },
  { name: "Kazakhstan", code: "KZ", callingCode: "77" },
  { name: "Kenya", code: "KE", callingCode: "254" },
  { name: "Kyrgyzstan", code: "KG", callingCode: "996" },
  { name: "Cambodia", code: "KH", callingCode: "855" },
  { name: "Kiribati", code: "KI", callingCode: "686" },
  { name: "Saint Kitts and Nevis", code: "KN", callingCode: "1869" },
  { name: "South Korea", code: "KR", callingCode: "82" },
  { name: "Kosovo", code: "XK", callingCode: "383" },
  { name: "Kuwait", code: "KW", callingCode: "965" },
  { name: "Laos", code: "LA", callingCode: "856" },
  { name: "Lebanon", code: "LB", callingCode: "961" },
  { name: "Liberia", code: "LR", callingCode: "231" },
  { name: "Libya", code: "LY", callingCode: "218" },
  { name: "Saint Lucia", code: "LC", callingCode: "1758" },
  { name: "Liechtenstein", code: "LI", callingCode: "423" },
  { name: "Sri Lanka", code: "LK", callingCode: "94" },
  { name: "Lesotho", code: "LS", callingCode: "266" },
  { name: "Lithuania", code: "LT", callingCode: "370" },
  { name: "Luxembourg", code: "LU", callingCode: "352" },
  { name: "Latvia", code: "LV", callingCode: "371" },
  { name: "Macau", code: "MO", callingCode: "853" },
  { name: "Saint Martin", code: "MF", callingCode: "590" },
  { name: "Morocco", code: "MA", callingCode: "212" },
  { name: "Monaco", code: "MC", callingCode: "377" },
  { name: "Moldova", code: "MD", callingCode: "373" },
  { name: "Madagascar", code: "MG", callingCode: "261" },
  { name: "Maldives", code: "MV", callingCode: "960" },
  { name: "Mexico", code: "MX", callingCode: "52" },
  { name: "Marshall Islands", code: "MH", callingCode: "692" },
  { name: "Macedonia", code: "MK", callingCode: "389" },
  { name: "Mali", code: "ML", callingCode: "223" },
  { name: "Malta", code: "MT", callingCode: "356" },
  { name: "Myanmar", code: "MM", callingCode: "95" },
  { name: "Montenegro", code: "ME", callingCode: "382" },
  { name: "Mongolia", code: "MN", callingCode: "976" },
  { name: "Northern Mariana Islands", code: "MP", callingCode: "1670" },
  { name: "Mozambique", code: "MZ", callingCode: "258" },
  { name: "Mauritania", code: "MR", callingCode: "222" },
  { name: "Montserrat", code: "MS", callingCode: "1664" },
  { name: "Martinique", code: "MQ", callingCode: "596" },
  { name: "Mauritius", code: "MU", callingCode: "230" },
  { name: "Malawi", code: "MW", callingCode: "265" },
  { name: "Malaysia", code: "MY", callingCode: "60" },
  { name: "Mayotte", code: "YT", callingCode: "262" },
  { name: "Namibia", code: "NA", callingCode: "264" },
  { name: "New Caledonia", code: "NC", callingCode: "687" },
  { name: "Niger", code: "NE", callingCode: "227" },
  { name: "Norfolk Island", code: "NF", callingCode: "672" },
  { name: "Nigeria", code: "NG", callingCode: "234" },
  { name: "Nicaragua", code: "NI", callingCode: "505" },
  { name: "Niue", code: "NU", callingCode: "683" },
  { name: "Netherlands", code: "NL", callingCode: "31" },
  { name: "Norway", code: "NO", callingCode: "47" },
  { name: "Nepal", code: "NP", callingCode: "977" },
  { name: "Nauru", code: "NR", callingCode: "674" },
  { name: "New Zealand", code: "NZ", callingCode: "64" },
  { name: "Oman", code: "OM", callingCode: "968" },
  { name: "Pakistan", code: "PK", callingCode: "92" },
  { name: "Panama", code: "PA", callingCode: "507" },
  { name: "Pitcairn Islands", code: "PN", callingCode: "64" },
  { name: "Perú", code: "PE", callingCode: "51" },
  { name: "Philippines", code: "PH", callingCode: "63" },
  { name: "Palau", code: "PW", callingCode: "680" },
  { name: "Papua New Guinea", code: "PG", callingCode: "675" },
  { name: "Poland", code: "PL", callingCode: "48" },
  { name: "Puerto Rico", code: "PR", callingCode: "1787" },
  { name: "Puerto Rico", code: "PR", callingCode: "1939" },
  { name: "North Korea", code: "KP", callingCode: "850" },
  { name: "Portugal", code: "PT", callingCode: "351" },
  { name: "Paraguay", code: "PY", callingCode: "595" },
  { name: "Palestine", code: "PS", callingCode: "970" },
  { name: "French Polynesia", code: "PF", callingCode: "689" },
  { name: "Qatar", code: "QA", callingCode: "974" },
  { name: "RÃ©union", code: "RE", callingCode: "262" },
  { name: "Romania", code: "RO", callingCode: "40" },
  { name: "Russia", code: "RU", callingCode: "7" },
  { name: "Rwanda", code: "RW", callingCode: "250" },
  { name: "Saudi Arabia", code: "SA", callingCode: "966" },
  { name: "Sudan", code: "SD", callingCode: "249" },
  { name: "Senegal", code: "SN", callingCode: "221" },
  { name: "Singapore", code: "SG", callingCode: "65" },
  { name: "South Georgia", code: "GS", callingCode: "500" },
  { name: "Svalbard and Jan Mayen", code: "SJ", callingCode: "4779" },
  { name: "Solomon Islands", code: "SB", callingCode: "677" },
  { name: "Sierra Leone", code: "SL", callingCode: "232" },
  { name: "El Salvador", code: "SV", callingCode: "503" },
  { name: "San Marino", code: "SM", callingCode: "378" },
  { name: "Somalia", code: "SO", callingCode: "252" },
  { name: "Saint Pierre and Miquelon", code: "PM", callingCode: "508" },
  { name: "Serbia", code: "RS", callingCode: "381" },
  { name: "South Sudan", code: "SS", callingCode: "211" },
  { name: "SÃ£o TomÃ© and PrÃ­ncipe", code: "ST", callingCode: "239" },
  { name: "Suriname", code: "SR", callingCode: "597" },
  { name: "Slovakia", code: "SK", callingCode: "421" },
  { name: "Slovenia", code: "SI", callingCode: "386" },
  { name: "Sweden", code: "SE", callingCode: "46" },
  { name: "Swaziland", code: "SZ", callingCode: "268" },
  { name: "Sint Maarten", code: "SX", callingCode: "1721" },
  { name: "Seychelles", code: "SC", callingCode: "248" },
  { name: "Syria", code: "SY", callingCode: "963" },
  { name: "Turks and Caicos Islands", code: "TC", callingCode: "1649" },
  { name: "Chad", code: "TD", callingCode: "235" },
  { name: "Togo", code: "TG", callingCode: "228" },
  { name: "Thailand", code: "TH", callingCode: "66" },
  { name: "Tajikistan", code: "TJ", callingCode: "992" },
  { name: "Tokelau", code: "TK", callingCode: "690" },
  { name: "Turkmenistan", code: "TM", callingCode: "993" },
  { name: "Timor-Leste", code: "TL", callingCode: "670" },
  { name: "Tonga", code: "TO", callingCode: "676" },
  { name: "Trinidad and Tobago", code: "TT", callingCode: "1868" },
  { name: "Tunisia", code: "TN", callingCode: "216" },
  { name: "Turkey", code: "TR", callingCode: "90" },
  { name: "Tuvalu", code: "TV", callingCode: "688" },
  { name: "Taiwan", code: "TW", callingCode: "886" },
  { name: "Tanzania", code: "TZ", callingCode: "255" },
  { name: "Uganda", code: "UG", callingCode: "256" },
  { name: "Ukraine", code: "UA", callingCode: "380" },
  { name: "Uruguay", code: "UY", callingCode: "598" },
  { name: "United States", code: "US", callingCode: "1" },
  { name: "Uzbekistan", code: "UZ", callingCode: "998" },
  { name: "Vatican City", code: "VA", callingCode: "3906698" },
  { name: "Vatican City", code: "VA", callingCode: "379" },
  { name: "Saint Vincent and the Grenadines", code: "VC", callingCode: "1784" },
  { name: "Venezuela", code: "VE", callingCode: "58" },
  { name: "British Virgin Islands", code: "VG", callingCode: "1284" },
  { name: "United States Virgin Islands", code: "VI", callingCode: "1340" },
  { name: "Vietnam", code: "VN", callingCode: "84" },
  { name: "Vanuatu", code: "VU", callingCode: "678" },
  { name: "Wallis and Futuna", code: "WF", callingCode: "681" },
  { name: "Samoa", code: "WS", callingCode: "685" },
  { name: "Yemen", code: "YE", callingCode: "967" },
  { name: "South Africa", code: "ZA", callingCode: "27" },
  { name: "Zambia", code: "ZM", callingCode: "260" },
  { name: "Zimbabwe", code: "ZW", callingCode: "263" }
];
export { code };
